.instructors-section {
}

.instructors-section a {
    text-decoration: underline;
}

.instructor-section div.picture {
    padding: 2em;
}

.instructor-section div.picture img {
    filter: grayscale(70%);
}

.instructor-section div.picture img:hover, .instructor-section div.picture img:active {
    filter: grayscale(0%);
}

.instructor-section li {
    list-style-type: square;
}
