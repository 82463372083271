.courses-section {
}

.courses-section a {
    text-decoration: underline;
}

.courses-section button, .courses-section a.btn {
    height: 6em;
    border: 2px solid black;
    width: 100%;
    /*font-family: KravMagaFont;*/
    /*font-size: 2em;*/
    text-shadow: black 4px 5px;
    border-radius: 0;
    text-decoration: none;

}

.courses-section button:hover, .courses-section button:active {
    background-color:black;
}