div {
  /*border: 1px solid red;*/
}

html, body {
  overflow-x: hidden;
}

body {
  background-color: #000;
  background-image: url("../public/img/background-main.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: repeat-y;
  color: #ddd;
}

a {
  text-decoration: none;
  color: #ddd;
}

a:hover, a:active {
  color: #ea868f;
}

@font-face {
  font-family: kravMagaFont;
  src: local("SairaStencilOne.ttf"), url("fonts/SairaStencilOne.ttf") format("truetype");
}

@font-face {
  font-family: bodyFont;
  src: local("WorkSans.ttf"), url("fonts/WorkSans.ttf") format("truetype");
}

.krav-maga-font {
  font-family: "KravMagaFont";
}

.body-font {
  font-family: "BodyFont";
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* to prevent background flickering */
.popover {
  pointer-events: none;
}

.top-carousel {
  height:500px;
  overflow: hidden;
}