.classes-section {
}

.classes-section a {
    text-decoration: underline;
}

.classes-section p.aikido {
    border: 1px solid yellow;
}
.classes-section p.kravka {
    border: 1px solid red;
}

.classes-section p.safeteens {
    border: 1px solid blue;
}

.classes-section p.safekids {
    border: 1px solid lightblue;
}