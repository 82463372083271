.top-navbar {
    padding: 0;
    z-index:100;
}

.top-navbar a:hover {
    background-color: gray;
    color: black;
}

.top-navbar a.navbar-brand:hover {
    background-color: transparent;
}

@media (min-width: 576px) {
    #navbarSupportedContent {
        height: 40px;
    }

    a.navbar-brand {
        float: left;
        height: 40px;
        padding: 0 100px 0 0;
    }

    img.logo-image {
        width: 100px;
    }
}

@media (max-width: 160px) {
    img.logo-image {
        display: none;
    }
    div.navbar-header {
        display: none;
    }
    button.navbar-toggler {
        margin:auto;
    }
}

@media (max-width: 576px) {
    a.navbar-brand {
        float: left;
        /*height: 60;*/
        padding: 0 0 0 0;
    }

    img.logo-image {
        width: 60px;
    }

    div.navbar-collapse {
        text-align: center;
    }
}