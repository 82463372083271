h1 {
    font-family: "KravMagaFont";
    text-transform: uppercase;
    font-size: 2em;
}

h2 {
    font-family: "KravMagaFont";
    text-transform: uppercase;
    font-size: 1.75em;
}

h3 {
    font-family: "KravMagaFont";
    text-transform: uppercase;
    font-size: 1.5em;
}

h4 {
    font-family: "KravMagaFont";
    text-transform: uppercase;
    font-size: 1.25em;
}

h5 {
    font-family: "KravMagaFont";
    text-transform: uppercase;
    font-size: 1em;
}

.picture-gray img {
    filter: grayscale(70%);
}

.picture-gray img:hover, .picture-gray img:active {
    filter: grayscale(0%);
}

.section-wrapper-background {
    background-image: url("../../public/img/background-section-1200.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: repeat-y;
    width: 100%;
    padding: 4em 0em;
}
section.container {
    padding-top: 1em;
    padding-bottom: 1em;
}

/*.popover-link {*/
/*    text-decoration: underline;*/
/*    pointer-sty*/
/*}*/