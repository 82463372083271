@keyframes top-bar-trainer {
    from { opacity: 0; }
    to { opacity: 1; }
}

.top-bar .top-bar-trainer > img {
    opacity: 0;
    animation: top-bar-trainer 1s 0s 1 forwards;
}

@keyframes top-bar-logo {
    0% {
        opacity: 0;
        visibility: hidden;
    }
    1% {
        opacity: 0;
        visibility: visible;
    }
    100% {
        opacity: 1;
        visibility: visible;
    }
}

.top-bar .top-bar-logo {
    visibility: hidden;
    animation: top-bar-logo 1s 1s 1 forwards;
}

.top-bar .krav-maga-font {
    filter: drop-shadow(5px 5px #000);
    rotate: 15deg;
}

section.top-bar {
    background-image: url("../../public/components/top-bar/background.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

section.tob-bar-main {

}

section.top-bar-aikido {
    background-image: url("../../public/components/top-bar/background-aikido.jpg");
}

section.top-bar-camp {
    background-image: url("../../public/components/top-bar/background-camp.jpg");
}

@media (max-width: 576px) {
    .top-bar .krav-maga-font {
        rotate: 0deg;
        text-align: center;
        vertical-align: middle;
    }

    /*section.top-bar {*/
    /*    height: 250px;*/
    /*}*/

    section.top-bar img {
        height:200px;
    }

    section.top-bar .appear {
        height:200px;
        width: 100px;
        margin: 0 auto;
        overflow: hidden;
    }
}

@media (min-width: 576px) {
    section.top-bar {
        height: 500px;
        /*display: none;*/
    }
    section.top-bar img {
        height:500px;
    }

    section.top-bar .appear {
        height:500px;
        width: 250px;
        margin: 0 auto;
        overflow: hidden;
    }
}