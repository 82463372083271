.camp2024summer-section {
}

.camp2024summer-section a {
    text-decoration: underline;
}

.camp-box {
    height: 11em;
    border: 2px solid black;
    width: 100%;
    /*font-family: KravMagaFont;*/
    /*font-size: 2em;*/
    text-shadow: black 4px 5px;
    border-radius: 0;
    text-decoration: none;
    padding: 5px;
    text-align: center;
    background-color: rgb(33, 37, 41);
}

.camp-box:hover, .camp2024summer-section button:active {
    /*background-color:($hover-background);*/
}