.offer-section {
    padding: 1em 0px;
}
.offer-section div.picture {
    padding: 2em;
}

.popover .a {
    text-decoration: underline;
}

.offer-section .popover-link {
    background-color: transparent;
    border:0px;
    text-align: center;
}

.popover h3.popover-header > img {
    height: 1.2em;
    margin-right: 0.5em;
}